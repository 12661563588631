import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface KonditionAndProdukt{
    konditionId: number,
    name: string,
    wertType: string,
    produktId: number,
    art: string
}

const initalState: KonditionAndProdukt[] = [{konditionId: -1, name: "", wertType: "", produktId: -1, art: ""}]

const konditionProduktSlice = createSlice({
    name: "konditionProdukt",
    initialState: initalState,
    reducers: {
        addKonditionProdukt(state: KonditionAndProdukt[], action: PayloadAction<KonditionAndProdukt>){

            state.push({konditionId: action.payload.konditionId, name: action.payload.name, wertType: action.payload.wertType, produktId: action.payload.produktId, art: action.payload.art})
        },
    }
})

export const {
    addKonditionProdukt,
} = konditionProduktSlice.actions

export default konditionProduktSlice.reducer