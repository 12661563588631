import React from 'react';
import welcomeImage from '../../../assets/images/computer-767776.jpg';

interface WelcomeComponentProps { }

const WelcomeComponent: React.FC<WelcomeComponentProps> = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        width: '95vw',
        overflow: 'hidden',
        margin: 0,
        
      }}
    >
      <img
        src={welcomeImage}
        alt="Willkommen"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          paddingLeft: '5vW'
        }}
      />
    </div>
  );
};

export default WelcomeComponent;