import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DataValueWettbewerber {
    date: string, //Datum
    value: number, //wert
    name: string, //Despo, Zinssatz1 ab 5000
    wettbewerber: string, //deutsche bank
    konditionId: number
}

const initalState: DataValueWettbewerber[] = [{ date: "", value: -1, name: "", wettbewerber: "", konditionId: -1 }]

const wettbewerberSlice = createSlice({
    name: "wettbewerber",
    initialState: initalState,
    reducers: {
        addWettbewerber(state: DataValueWettbewerber[], action: PayloadAction<DataValueWettbewerber>) {

            state.push(
                {
                    date: action.payload.date,
                    value: action.payload.value,
                    name: action.payload.name,
                    wettbewerber: action.payload.wettbewerber,
                    konditionId: action.payload.konditionId
                }
            )
        },
    }
})

export const {
    addWettbewerber,
} = wettbewerberSlice.actions

export default wettbewerberSlice.reducer