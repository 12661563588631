import { UnterorgNumKonWertDurchschnittDto } from "../rest/entities/unterorgkondwertdurchschnitteNew";
import { BASIS_URL, fetchInstitute, fetchNumerischeKonditionswerte, fetchUnterorgkondwertdurchschnitte, } from "../service/rest-service";
import { KonditionAndProdukt } from "../store/konditionProduktSlice";
import { NumerischerKonditionswertDto } from "../rest/entities/numerische_konditionswerteNew";
import { NavItemTree } from "s-ms-component-library";
import { DataValueWettbewerber } from "../store/wettbewerberSlice";
import { DateValue } from "../objekt/types"
import { InstitutDto } from "../rest/entities/institute";
import { VerbandUnterorganisationen } from "../store/verbandUnterorganisationSlice";

export const extractLabelsFromUrl = (url: string) => {
    const urlObject = new URL(url);
    const basePath = `${urlObject.protocol}//${urlObject.host}/`;
    return url.replace(basePath, '')
        .split('/')
        .map(decodeURIComponent);
};

export const findIds = (labels: string[], nodes: NavItemTree[] | undefined): string[] => {
    if (labels.length === 0 || !nodes) {
        return [];
    }

    for (let node of nodes) {
        if (node.label.toLowerCase().replace(/\s+/g, '-') === labels[0].toLowerCase()) {
            const remainingLabels = labels.slice(1);
            if (remainingLabels.length === 0) {
                return [node.id];
            }
            const childResult = findIds(remainingLabels, node.children);
            if (childResult.length > 0) {
                return [node.id, ...childResult];
            }
        }
    }
    return [];
};

export function dataManager(root: string[], startDate: string, endDate: string, setData: any, setWettbewerberData: any, setKonditionsData: any, selectedRange: string, konditonProdukt: KonditionAndProdukt[], verbandUnterorganisationen: VerbandUnterorganisationen[]) {

    if (root.length === 3) {
        //hier landen alle Unterorganisationen und Konditionsübersicht
        if (root[1] === "0") {
            //Konditionsübersicht
            if (root[2] === "1") {
                //Gesamt
                loadGesamt(startDate, endDate, setData, setWettbewerberData, selectedRange, konditonProdukt, verbandUnterorganisationen, setKonditionsData);
            } else {
                //Alle anderen in der Konditionübersicht
                loadKonditionsuebersicht(root, startDate, endDate, setData, setWettbewerberData, selectedRange, konditonProdukt, verbandUnterorganisationen, setKonditionsData);
            }
        } else {
            //alle Unterorganisationen
            loadUnterorganisationDataArray(root, startDate, endDate, setData, setWettbewerberData, selectedRange, konditonProdukt)
        }
    } else if (root.length === 4) {
        //Alle Sparkassen
        loadSparkassenDataArray(root, startDate, endDate, setData, setWettbewerberData, selectedRange, konditonProdukt)
    } else {
        console.log(root);
    }
}

export const fetchDataUnterorgNumKonWertDurchschnitt = async (startDatum: string, endDatum: string, idUnterorganisation: number): Promise<UnterorgNumKonWertDurchschnittDto[]> => {
    const url = `${BASIS_URL}/unterorgkondwertdurchschnitte/search/findByGueltigkeitstagAfterAndGueltigkeitstagBeforeAndUnterorganisation_IdOrderByGueltigkeitstagAscKondition_IdAsc?startDate=${startDatum}&endDate=${endDatum}&unterorganisationId=${idUnterorganisation}`;
    return await fetchUnterorgkondwertdurchschnitte(url);
}

const fetchDataNumerischeKonditionswerte = async (startDatum: string, endDatum: string, institutId: number): Promise<NumerischerKonditionswertDto[]> => {
    const url = `${BASIS_URL}/numerische_konditionswerte/search/findByInstitut_IdAndGueltigkeitstagAfterAndGueltigkeitstagBeforeOrderByGueltigkeitstagAscKondition_IdAsc?institutId=${institutId}&startDate=${startDatum}&endDate=${endDatum}`;
    return await fetchNumerischeKonditionswerte(url);
}

const fetchDataKonditionsuebersicht = async (startDatum: string, endDatum: string, konditionId: number): Promise<UnterorgNumKonWertDurchschnittDto[]> => {
    const url = `${BASIS_URL}/unterorgkondwertdurchschnitte/search/findByGueltigkeitstagAfterAndGueltigkeitstagBeforeAndKondition_IdOrderByGueltigkeitstagAscKondition_IdAsc?startDate=${startDatum}&endDate=${endDatum}&konditionId=${konditionId}`;
    return await fetchUnterorgkondwertdurchschnitte(url);
}

const fetchDataUnterorganisationsDurchschnitt = async (startDatum: string, endDatum: string, unterorganisatonsID: number): Promise<UnterorgNumKonWertDurchschnittDto[]> => {
    const url = `${BASIS_URL}/unterorgkondwertdurchschnitte/search/findByGueltigkeitstagAfterAndGueltigkeitstagBeforeAndUnterorganisation_IdOrderByGueltigkeitstagAscKondition_IdAsc?startDate=${startDatum}&endDate=${endDatum}&unterorganisationId=${unterorganisatonsID}`;
    return await fetchUnterorgkondwertdurchschnitte(url);
}

export interface Gesamt {
    untergoranisationsName: string,
    value: number,
    date: string,
    artDesProdukts: string,
    nameDesProdukts: string
}

export interface GesamtData {
    untergoranisationsName: string,
    value: number,
    date: string,
    nameDesProdukts: string
}

async function loadGesamt(startDate: string, endDate: string, setData: any, setWettbewerberData: any, selectedRange: string, konditonProdukt: KonditionAndProdukt[], verbandUnterorganisationen: VerbandUnterorganisationen[], setKonditionsData: any) {
 
    let gesamtListe: Gesamt[] = [];

    await Promise.all(verbandUnterorganisationen.map(async (verbandUnterorganisation) => {
        const results = await fetchDataUnterorganisationsDurchschnitt(startDate, endDate, verbandUnterorganisation.unterorganisationsId);

        results.forEach((item) => {
            const konditonProduktMitRichtigerId = konditonProdukt.find((kP) => {
                return kP.konditionId === item.konditionId;
            });

            if (konditonProduktMitRichtigerId) {
                const gesamt: Gesamt = {
                    untergoranisationsName: verbandUnterorganisation.unterorganisationsName,
                    value: item.wert!,
                    date: item.gueltigkeitstag!,
                    artDesProdukts: konditonProduktMitRichtigerId.art,
                    nameDesProdukts: konditonProduktMitRichtigerId.name
                };
                gesamtListe.push(gesamt);
            }
        });
    }));

    const resultMap: { [key: string]: { totalValue: number; count: number; aggregatedProduct: GesamtData } } = {};

    gesamtListe.forEach(item => {
        const key = `${item.untergoranisationsName}-${item.date}-${item.artDesProdukts}`;

        if (!resultMap[key]) {
            resultMap[key] = {
                totalValue: 0,
                count: 0,
                aggregatedProduct: {
                    value: 0,
                    date: item.date,
                    untergoranisationsName: item.untergoranisationsName,
                    nameDesProdukts:  item.nameDesProdukts.split(" ")[0] === "ab" 
                    ? "Tagesgeld" 
                    : item.nameDesProdukts.split(" ")[0]
                }
            };
        }

        resultMap[key].totalValue += item.value;
        resultMap[key].count += 1;
    });

    const resultList: GesamtData[] = Object.values(resultMap).map(entry => {
        entry.aggregatedProduct.value = entry.totalValue / entry.count;
        return entry.aggregatedProduct;
    });
    setKonditionsData(resultList);


    loadInstitute(selectedRange).then((wettbewerberArray) => {
        const groupedData = wettbewerberArray.reduce((acc, item) => {
            const namePrefix = item.name.split(" ")[0];
            const key = `${item.date}_${namePrefix}_${item.wettbewerber}`;
            
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            
            return acc;
        }, {} as Record<string, DataValueWettbewerber[]>);
    
        const averagedData: DataValueWettbewerber[] = Object.values(groupedData).map(group => {
            const sumValue = group.reduce((sum, item) => sum + item.value, 0);
            const count = group.length;
            const averageValue = sumValue / count;
            const { date, name, wettbewerber, konditionId } = group[0];

            return {
                date: date,
                value: averageValue,
                name: name.split(" ")[0] === "ab" 
                ? "Tagesgeld" 
                : name.split(" ")[0],
                wettbewerber: wettbewerber,
                konditionId: konditionId
            };
        });
        
        setWettbewerberData(averagedData);
    })
   
}

async function loadKonditionsuebersicht(input: string[], startDate: string, endDate: string, setData: any, setWettbewerberData: any, selectedRange: string, konditonProdukt: KonditionAndProdukt[], verbandUnterorganisationen: VerbandUnterorganisationen[], setKonditionsData: any) {

    const produktId: number = parseInt(input[2]) - 1;

    const konditionAndProduktArray: KonditionAndProdukt[] = konditonProdukt.filter((value) => value.produktId === produktId);

    const konditionsübersichtListe: GesamtData[] = [];

    
    await Promise.all(verbandUnterorganisationen.map(async (verbandUnterorganisation) => {
        const results: UnterorgNumKonWertDurchschnittDto[] = await fetchDataUnterorganisationsDurchschnitt(startDate, endDate, verbandUnterorganisation.unterorganisationsId);

        results.map((result) => {

            konditionAndProduktArray.forEach((konditionAndProdukt) => {

                if(konditionAndProdukt.konditionId === result.konditionId){
                    const ob: GesamtData = {
                        untergoranisationsName: verbandUnterorganisation.unterorganisationsName,
                        value: result.wert!,
                        date: result.gueltigkeitstag!,
                        nameDesProdukts: konditionAndProdukt.name
                    }
                    konditionsübersichtListe.push(ob)
                }
            })
        })
    }))
    setKonditionsData(konditionsübersichtListe)
    loadWettbewerberArray(konditionAndProduktArray, setWettbewerberData, selectedRange);

}


function loadUnterorganisationDataArray(input: string[], startDate: string, endDate: string, setData: any, setWettbewerberData: any, selectedRange: string, konditonProdukt: KonditionAndProdukt[]) {
    //TODO hier muss ich noch schaen das ich nur die Unterorganisatonen nehme die auch in dem richtigen sparkassenverband sind
    const unterorganisationsId: number = parseInt(input[1]);
    const konditionAndProduktArray: KonditionAndProdukt[] = getKonditionAndProduktWithRootElement(parseInt(input[2]), konditonProdukt);
    const liste: Promise<UnterorgNumKonWertDurchschnittDto[]> = fetchDataUnterorgNumKonWertDurchschnitt(startDate, endDate, unterorganisationsId);

    loadWettbewerberArray(konditionAndProduktArray, setWettbewerberData, selectedRange);

    buildFilteredArray(liste, konditionAndProduktArray).then((filteredList) => {
        setData(filteredList);
    });
}

function loadSparkassenDataArray(input: string[], startDate: string, endDate: string, setData: any, setWettbewerberData: any, selectedRange: string, konditonProdukt: KonditionAndProdukt[]) {
    //TODO hier muss ich noch schaen das ich nur die Sparkassen nehme die auch in dem richtigen sparkassenverband sind
    const sparkassenId: number = parseInt(input[2]);
    const konditionAndProduktArray: KonditionAndProdukt[] = getKonditionAndProduktWithRootElement(parseInt(input[3]), konditonProdukt);
    const list: Promise<NumerischerKonditionswertDto[]> = fetchDataNumerischeKonditionswerte(startDate, endDate, sparkassenId);

    loadWettbewerberArray(konditionAndProduktArray, setWettbewerberData, selectedRange);

    buildFilteredArray(list, konditionAndProduktArray).then((filteredList) => {
        setData(filteredList)
    });
}

function loadWettbewerberArray(konditionAndProduktArray: KonditionAndProdukt[], setWettbewerberData: any, selectedRange: string) {
    const konditionIds = konditionAndProduktArray.map((konditonsProdukt) => {
        return konditonsProdukt.konditionId
    })

    loadInstitute(selectedRange).then((wettbewerberArray) => {
        const filterdList = wettbewerberArray.filter((wettbewerber) => {
            return konditionIds.includes(wettbewerber.konditionId);
        })
        setWettbewerberData(filterdList);

    })

}

function buildFilteredArray<T>(listPromise: Promise<T[]>, konditionAndProduktArray: KonditionAndProdukt[]): Promise<DateValue[]> {
    return listPromise.then((list) =>
        Promise.all(
            list.map(async (value) => {
                let konditionId = (value as any).konditionId;

                if (konditionId === undefined && (value as any).kondition) {
                    konditionId = (value as any).kondition.id;
                }

                const matchingEntry = konditionAndProduktArray.find((v) => v.konditionId === konditionId);

                if (matchingEntry) {
                    const dataValue: DateValue = {
                        date: (value as any).gueltigkeitstag,
                        value: (value as any).wert,
                        name: matchingEntry.name
                    }
                    return dataValue
                }

                return null;
            })
        ).then((dateValues) => dateValues.filter((dateValue) => dateValue !== null) as DateValue[])
    );
}

export function getKonditionAndProduktWithRootElement(produktId: number, konditonProdukt: KonditionAndProdukt[]): KonditionAndProdukt[] {
    return konditonProdukt.filter((value) => {
        return value.produktId === produktId;
    });
}

export const fetchfindInstituteByIds = async (wettbewerberIds: string, range: string): Promise<NumerischerKonditionswertDto[]> => {
    const startDatum = getDateEarlierFormatted(range);
    const enddatum = getCurrentDateFormatted();
    const url = `${BASIS_URL}/numerische_konditionswerte/search/findByInstitut_IdInAndGueltigkeitstagAfterAndGueltigkeitstagBeforeOrderByGueltigkeitstagAscKondition_IdAsc?institutIds=${wettbewerberIds}&startDate=${startDatum}&endDate=${enddatum}`;
    return await fetchNumerischeKonditionswerte(url);
}

export const fetchDataInstitute = async (): Promise<InstitutDto[]> => {
    const url = `${BASIS_URL}/institute`
    return await fetchInstitute(url)
}


export async function loadInstitute(range: string): Promise<DataValueWettbewerber[]> {
    const institut: InstitutDto[] = (await fetchDataInstitute())

    const wettbewerberIds = institut.map((institut) => institut.id).join(",");
    const numerischeKonditonsWerte: NumerischerKonditionswertDto[] = await fetchfindInstituteByIds(wettbewerberIds, range);
    const dateValueWetbewerberArray = numerischeKonditonsWerte.map((numerischeKonditonsWert) => {

        const dateValueWettbewerber: DataValueWettbewerber = {
            date: numerischeKonditonsWert.gueltigkeitstag!,
            value: numerischeKonditonsWert.wert!,
            name: numerischeKonditonsWert.kondition?.name!,
            wettbewerber: numerischeKonditonsWert.institut?.name!,
            konditionId: numerischeKonditonsWert.kondition?.id!
        }
        return dateValueWettbewerber
    })
    return dateValueWetbewerberArray
}

export function getCurrentDateFormatted(): string {
    const now = new Date();
    now.setUTCHours(now.getUTCDay() - 24);
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function getDateEarlierFormatted(range: string): string {
    const now = new Date();
    if (range === '6 Monate') {
        now.setUTCMonth(now.getUTCMonth() - 6);
        now.setUTCFullYear(now.getUTCFullYear());
    } else if (range === '1 Jahr') {
        now.setUTCMonth(now.getUTCMonth());
        now.setUTCFullYear(now.getUTCFullYear() - 1);
    } else if (range === '3 Jahre') {
        now.setUTCMonth(now.getUTCMonth());
        now.setUTCFullYear(now.getUTCFullYear() - 3);
    } else {
        now.setUTCMonth(now.getUTCMonth() - 3);
        now.setUTCFullYear(now.getUTCFullYear());
    }

    const year = String(now.getUTCFullYear()).padStart(2, '0');
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}