import './App.css';
import 's-ms-component-library/dist/styles/styles.css'
import 's-ms-component-library/dist/styles/components.css'
import 's-ms-component-library/dist/assets/fonts/fonts.css'
import 's-ms-component-library/dist/assets/fonts/material-icons/material-icons.css'
import appLogo from './assets/images/testlogo.png'
import { AnwendungskopfLogoLinks, AnwendungskopfRechts, Header, Institutsname } from 's-ms-component-library';
import NavigationComponent from './ui/components/navigation';
import { BrowserRouter } from 'react-router-dom';


const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Header>
          <AnwendungskopfLogoLinks>
            <img src={appLogo} alt="Applikationslogo" />
          </AnwendungskopfLogoLinks>
          <AnwendungskopfRechts key="icons">
            <Institutsname name="Sparkassenverband" />
          </AnwendungskopfRechts>
        </Header>

        <NavigationComponent/>
      </BrowserRouter>
    </div>
  )
}

export default App;
