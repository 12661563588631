import React, { useEffect, useState } from 'react';
import LineChart from './LineChart';
import ComboBox from './ComboBox';
import { Checkbox } from 's-ms-component-library';
import { DateValue, DateValueInstitut, GesamtData } from '../../objekt/types';
import { DataValueWettbewerber } from "../../store/wettbewerberSlice";
import { extractLabelsFromUrl } from '../../utils/GraphenUtils';

interface PageComponentProps {
  chartData: DateValue[];
  wettbewerberdata: DataValueWettbewerber[];
  selectedRange: string;
  setSelectedRange: any;
  konditionsData: GesamtData[];
}

// Helper function to transform ISO date to dd.mm.yyyy
const formatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

const PageComponent: React.FC<PageComponentProps> = ({ chartData, wettbewerberdata, selectedRange, setSelectedRange, konditionsData }) => {
  const [filteredData, setFilteredData] = useState<DateValue[]>([]);
  const [filteredDataWettbewerber, setFilteredDataWettbewerber] = useState<DateValueInstitut[]>([]);
  const [filteredDataKondition, setFilteredDataKondition] = useState<GesamtData[]>([]);
  const [checkboxState, setCheckboxState] = useState(2);

  const handleCheckboxChange = (checked: boolean | number) => {
    const newState = checked === true || checked === 1 ? 1 : 2;
    setCheckboxState(newState);
  };

  // Function to handle range changes
  const handleRangeChange = (value: string) => {
    setSelectedRange(value);

    if (!value) {
      setFilteredData(chartData.map(item => ({
        ...item,
        date: formatDate(item.date),
      })));
      return;
    }

    let startDate = new Date();
    const today = new Date();

    switch (value) {
      case '6 Monate':
        startDate.setMonth(today.getMonth() - 6);
        break;
      case '3 Monate':
        startDate.setMonth(today.getMonth() - 3);
        break;
      case '1 Jahr':
        startDate.setFullYear(today.getFullYear() - 1);
        break;
      case '3 Jahre':
        startDate.setFullYear(today.getFullYear() - 3);
        break;
      default:
        setFilteredData(chartData.map(item => ({
          ...item,
          date: formatDate(item.date),
        })));
        return;
    }

    // Filter data based on the start date
    const filtered = chartData
      .map(item => ({
        ...item,
        date: formatDate(item.date),
      }))
      .filter((entry) => {
        const [day, month, year] = entry.date.split('.').map(Number);
        const entryDate = new Date(year, month - 1, day);
        return entryDate >= startDate;
      });
    setFilteredData(filtered);

    const filteredWettbewerber = wettbewerberdata
    .map(item => ({
      ...item,
      date: formatDate(item.date),
    }))
    .filter((entry) => {
      const [day, month, year] = entry.date.split('.').map(Number);
      const entryDate = new Date(year, month - 1, day);
      return entryDate >= startDate;
    });
    setFilteredDataWettbewerber(filteredWettbewerber);

    const filteredKondition = konditionsData
    .map(item => ({
      ...item,
      date: formatDate(item.date),
    }))
    .filter((entry) => {
      const [day, month, year] = entry.date.split('.').map(Number);
      const entryDate = new Date(year, month - 1, day);
      return entryDate >= startDate;
    });
    setFilteredDataKondition(filteredKondition);
  };

  // Initialize filteredData based on the initial selectedRange
  useEffect(() => {
    handleRangeChange(selectedRange);
  }, [selectedRange, chartData, wettbewerberdata, konditionsData]);

  const currentUrl = window.location.href;
  const labels = extractLabelsFromUrl(currentUrl);
  let title= ""
  if (labels[1] === "sparkassen") {
    title = `Prozente im gemessenen Zeitraum von ${String(labels[3])} in ${String(labels[2])}`;
  } else {
    title = `Prozente im gemessenen Zeitraum von ${String(labels[2])} in ${String(labels[1])}`;
  }

  return (
    <div style={{display: 'grid'}}>
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginLeft: '20vW', marginRight: '20vW'}}>
        <ComboBox value={selectedRange} onChange={handleRangeChange} options={['3 Monate','6 Monate', '1 Jahr', '3 Jahre']} label={"Betrachtungszeitraum"}/>
        <div style={{display: 'flex', marginLeft: '3vW'}}>
          <Checkbox
            checked={checkboxState}
            id="default-unchecked"
            labelText="Wettbewerber"
            onChange={(checked: boolean | number) => handleCheckboxChange(checked)}
          />
        </div>
      </div>
      <br/>
      <LineChart
        dataMap={filteredData}
        title={title}
        width={"80vW"}
        height={"60vH"}
        timeRange={selectedRange}
        checkboxState={checkboxState}
        wettbewerberData={filteredDataWettbewerber}
        konditionsData={filteredDataKondition}
      />
    </div>
  );
};

export default PageComponent;
