import { configureStore } from "@reduxjs/toolkit"
import navigationReducer from "./navigationSlice"
import urlReducer from "./urlSlice";
import konditionProduktReducer from "./konditionProduktSlice"
import wettberberReducer from "./wettbewerberSlice"
import verbandUnterorganisationenReducer from "./verbandUnterorganisationSlice";
import verbandSparkassenReducer from "./verbandSparkassenSlice"
import idsReducer from "./idsSlice"

const store = configureStore({
    reducer: {
        navigation: navigationReducer,
        url: urlReducer,
        konditionProdukt: konditionProduktReducer,
        wettbewerber: wettberberReducer,
        verbandUnterorganisationen: verbandUnterorganisationenReducer,
        verbandSparkassen: verbandSparkassenReducer,
        ids: idsReducer
    }
})

export type Store = ReturnType<typeof store.getState>;

export default store