import { DateValue } from '../objekt/types';

// Utility for rounding numbers
export const roundToThreeDecimals = (value: number): number => parseFloat(value.toFixed(3));

// Calculate bi-weekly averages
export const getBiWeeklyAverages = (data: DateValue[]): DateValue[] => {
  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const filteredData = data.filter(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const date = new Date(year, month - 1, day);
    return date >= oneYearAgo && date <= today;
  });

  const biWeeklyData: { [key: string]: { values: number[]; start: Date; end: Date } } = {};

  filteredData.forEach(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const date = new Date(year, month - 1, day);

    const startDay = date.getDate() <= 14 ? 1 : 15;
    const endDay = date.getDate() <= 14 ? 14 : new Date(year, month, 0).getDate();

    const start = new Date(year, month - 1, startDay);
    const end = new Date(year, month - 1, endDay);

    const key = `${start.toLocaleDateString('de-DE')} - ${end.toLocaleDateString('de-DE')}`;

    if (!biWeeklyData[key]) biWeeklyData[key] = { values: [], start, end };
    biWeeklyData[key].values.push(item.value);
  });

  return Object.entries(biWeeklyData).map(([key, data]) => ({
    date: key,
    value: roundToThreeDecimals(data.values.reduce((sum, val) => sum + val, 0) / data.values.length),
    name: '',
  }));
};

// Calculate weekly averages
export const getWeeklyAverages = (data: DateValue[]): DateValue[] => {
  const today = new Date();
  const sixMonthsAgo = new Date(today);
  sixMonthsAgo.setMonth(today.getMonth() - 6);

  const filteredData = data.filter(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const date = new Date(year, month - 1, day);
    return date >= sixMonthsAgo && date <= today;
  });

  const weeklyData: { [key: string]: number[] } = {};

  filteredData.forEach(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const date = new Date(year, month - 1, day);

    const startOfWeek = new Date(date);
    const dayOfWeek = startOfWeek.getDay();
    const diffToMonday = (dayOfWeek + 6) % 7;
    startOfWeek.setDate(startOfWeek.getDate() - diffToMonday);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const key = `${startOfWeek.toLocaleDateString('de-DE')} - ${endOfWeek.toLocaleDateString('de-DE')}`;

    if (!weeklyData[key]) weeklyData[key] = [];
    weeklyData[key].push(item.value);
  });

  return Object.entries(weeklyData).map(([key, values]) => ({
    date: key,
    value: roundToThreeDecimals(values.reduce((sum, val) => sum + val, 0) / values.length),
    name: '',
  }));
};


// Calculate monthly averages
export const getMonthlyAverages = (data: DateValue[]): DateValue[] => {
  const today = new Date();
  const threeYearsAgo = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());

  const filteredData = data.filter(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const date = new Date(year, month - 1, day);
    return date >= threeYearsAgo && date <= today;
  });

  const monthlyData: { [key: string]: number[] } = {};

  filteredData.forEach(item => {
    const [day, month, year] = item.date.split('.').map(Number);
    const key = `${year}-${month}`;
    if (!monthlyData[key]) monthlyData[key] = [];
    monthlyData[key].push(item.value);
  });

  return Object.entries(monthlyData).map(([key, values]) => {
    const [year, month] = key.split('-');
    return {
      date: `${month}.${year}`,
      value: roundToThreeDecimals(values.reduce((sum, val) => sum + val, 0) / values.length),
      name: '',
    };
  });
};

// Process data based on time range
export const processData = (data: DateValue[], timeRange: string): DateValue[] => {
  if (timeRange === '3 Jahre') return getMonthlyAverages(data);
  if (timeRange === '1 Jahr') return getBiWeeklyAverages(data);
  if (timeRange === '6 Monate') return getWeeklyAverages(data);
  return data;
};

export const predefinedColors = [
    'rgba(54, 162, 235, 0.8)',  // Blue
    'rgba(255, 99, 132, 0.8)',  // Red
    'rgba(75, 192, 192, 0.8)',  // Teal
    'rgba(255, 159, 64, 0.8)',  // Orange
    'rgba(153, 102, 255, 0.8)', // Purple
    'rgba(255, 206, 86, 0.8)',  // Yellow
    'rgba(201, 203, 207, 0.8)', // Gray
    'rgba(0, 204, 102, 0.8)',   // Green
    'rgba(255, 128, 0, 0.8)',   // Dark Orange
    'rgba(0, 128, 255, 0.8)',   // Sky Blue
    'rgba(204, 0, 102, 0.8)',   // Magenta
    'rgba(102, 204, 0, 0.8)',   // Lime Green
    'rgba(128, 0, 255, 0.8)',   // Violet
    'rgba(0, 255, 255, 0.8)',   // Cyan
    'rgba(255, 0, 128, 0.8)',   // Pink
    'rgba(128, 128, 0, 0.8)',   // Olive
    'rgba(0, 128, 128, 0.8)',   // Teal Green
    'rgba(128, 0, 0, 0.8)',     // Maroon
    'rgba(0, 0, 128, 0.8)',     // Navy Blue
    'rgba(255, 51, 51, 0.8)',   // Bright Red
    'rgba(51, 255, 51, 0.8)',   // Bright Green
    'rgba(51, 51, 255, 0.8)',   // Bright Blue
    'rgba(204, 255, 51, 0.8)',  // Chartreuse
    'rgba(255, 51, 204, 0.8)',  // Hot Pink
    'rgba(102, 51, 255, 0.8)',  // Indigo
    'rgba(255, 153, 51, 0.8)',  // Amber
    'rgba(0, 255, 128, 0.8)',   // Aquamarine
    'rgba(51, 102, 255, 0.8)',  // Cerulean Blue
    'rgba(255, 102, 51, 0.8)',  // Coral
    'rgba(51, 255, 153, 0.8)',  // Mint
    'rgba(204, 51, 255, 0.8)',  // Orchid
    'rgba(255, 255, 0, 0.8)',   // Bright Yellow
    'rgba(102, 255, 102, 0.8)', // Soft Green
    'rgba(255, 102, 102, 0.8)', // Soft Red
    'rgba(102, 102, 255, 0.8)', // Periwinkle
    'rgba(255, 204, 153, 0.8)', // Peach
    'rgba(153, 255, 204, 0.8)', // Pale Turquoise
    'rgba(204, 153, 255, 0.8)', // Lavender
    'rgba(255, 255, 153, 0.8)', // Pale Yellow
    'rgba(153, 204, 255, 0.8)', // Light Blue
    'rgba(255, 153, 204, 0.8)', // Light Pink
    'rgba(204, 255, 153, 0.8)', // Pale Lime
    'rgba(153, 153, 204, 0.8)', // Cool Gray
    'rgba(102, 255, 204, 0.8)'  // Turquoise
  ];
