import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VerbandSparkasse {
    verbandsId: number,
    verbandsName: string,
    sparkassenId: number,
    sparkassenName: string
}

const initalState: VerbandSparkasse[] = []

const verbandSparkassenSlice = createSlice({
    name: "verbandSparkassen",
    initialState: initalState,
    reducers: {
        addVerbandSparkassen(state: VerbandSparkasse[], action: PayloadAction<VerbandSparkasse>){

            state.push({verbandsId: action.payload.verbandsId, verbandsName: action.payload.verbandsName, sparkassenId: action.payload.sparkassenId, sparkassenName: action.payload.sparkassenName})
        },
    }
})

export const {
    addVerbandSparkassen,
} = verbandSparkassenSlice.actions

export default verbandSparkassenSlice.reducer