import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string[] = [];

const idsSlice = createSlice({
    name: "ids",
    initialState,
    reducers: {
        addIds(state, action: PayloadAction<string[]>) {
            state.push(...action.payload);
        },
        clearIds() {
            return initialState;
        }
    }
});

export const { addIds, clearIds } = idsSlice.actions;

export default idsSlice.reducer;