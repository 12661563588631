import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NavItemTree } from "s-ms-component-library"



export interface NavigationState{
    items: NavItemTree[]
}

const initalState: NavigationState = {items: []}

const navigationSlice = createSlice({
    name: "navigation",
    initialState: initalState,
    reducers: {
        add(state: NavigationState, action: PayloadAction<NavItemTree>){
            
            state.items.push(action.payload)
        },
        setAll(state: NavigationState, action: PayloadAction<NavItemTree[]>){
            state.items = action.payload
        }
    }
})

export const {
    add,
    setAll
} = navigationSlice.actions

export default navigationSlice.reducer
