import axios from "axios";
import { SparkasseDto } from "../rest/entities/sparkassen";
import { UnterorgNumKonWertDurchschnittDto } from "../rest/entities/unterorgkondwertdurchschnitteNew";
import { SparkassenverbandDto } from "../rest/entities/sparkassenverbaendeNew";
import { KonditionDto } from "../rest/entities/konditionenNew";
import { ProduktDto } from "../rest/entities/produkteNew";
import { UnterorganisationDto } from "../rest/entities/unterorganisationenNew";
import { NumerischerKonditionswertDto } from "../rest/entities/numerische_konditionswerteNew";
import { InstitutDto } from "../rest/entities/institute";


  const BASIS_URL: string = "https://konditionen-monitor.s-management-services.de/api"


  async function fetchSparkassenverbande(url: string): Promise<SparkassenverbandDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.sparkassenverbaende || [];
    } catch (error) {
      console.error('Error fetching Sparkassenverbande:', error);
      return [];
    }
  }
  
  async function fetchSparkassenverband(url: string): Promise<SparkassenverbandDto> {
    try {
      const response = await axios.get(url);
      return response.data || null;
    } catch (error) {
      console.error('Error fetching Sparkassenverband:', error);
      return {
        id: 0,
        name: "Unknown Produkt",
        beschreibung: "Default Produkt description",
      } as SparkassenverbandDto;
    }
  }
  
  async function fetchSparkassen(url: string): Promise<SparkasseDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.sparkassen || [];
    } catch (error) {
      console.error('Error fetching Sparkassen:', error);
      return [];
    }
  }
  
  async function fetchUnterorganisationen(url: string): Promise<UnterorganisationDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.unterorganisationen || [];
    } catch (error) {
      console.error('Error fetching Unterorganisationen:', error);
      return [];
    }
  }
  
  async function fetchUnterorganisation(url: string): Promise<UnterorganisationDto> {
    try {
      const response = await axios.get(url);
      return response.data || null;
    } catch (error) {
      console.error('Error fetching Unterorganisation:', error);
      return {
        id: 0,
        name: "Unknown Produkt",
        beschreibung: "Default Produkt description",
      } as UnterorganisationDto;
    }
  }
  
  async function fetchUnterorgkondwertdurchschnitte(url: string): Promise<UnterorgNumKonWertDurchschnittDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.unterorgkondwertdurchschnitte || [];
    } catch (error) {
      console.error('Error fetching Unterorgkondwertdurchschnitte:', error);
      return [];
    }
  }
  
  async function fetchKonditionen(url: string): Promise<KonditionDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.konditionen || [];
    } catch (error) {
      console.error('Error fetching Konditionen:', error);
      return [];
    }
  }
  
  async function fetchKondition(url: string): Promise<KonditionDto> {
    try {
      const response = await axios.get(url);
      return response.data || null;
    } catch (error) {
      console.error('Error fetching Kondition:', error);
      return {
        id: 0,
        name: "Unknown Produkt",
        beschreibung: "Default Produkt description",
      } as KonditionDto;
    }
  }
  
  async function fetchProdukt(url: string): Promise<ProduktDto> {
    try {
      const response = await axios.get(url);
      return response.data as ProduktDto;
    } catch (error) {
      console.error('Error fetching Produkt:', error);
      return {
        id: 0,
        name: "Unknown Produkt",
        beschreibung: "Default Produkt description",
      } as ProduktDto;
    }
  }
  
  
  async function fetchNumerischeKonditionswerte(url: string): Promise<NumerischerKonditionswertDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.numerische_konditionswerte || [];
    } catch (error) {
      console.error('Error fetching Numerische Konditionswerte:', error);
      return [];
    }
  }
  
  async function fetchNumerischeKonditionswert(url: string): Promise<NumerischerKonditionswertDto> {
    try {
      const response = await axios.get(url);
      return response.data || null;
    } catch (error) {
      console.error('Error fetching Numerische Konditionswert:', error);
      return {
        id: 0,
        name: "Unknown Produkt",
        beschreibung: "Default Produkt description",
      } as NumerischerKonditionswertDto;
    }
  }
  
  async function fetchInstitute(url: string): Promise<InstitutDto[]> {
    try {
      const response = await axios.get(url);
      return response.data._embedded?.institute || [];
    } catch (error) {
      console.error('Error fetching Institute:', error);
      return [];
    }
  }

export {
  fetchSparkassenverbande, fetchSparkassenverband, 
  fetchSparkassen, 
  fetchUnterorganisationen, fetchUnterorganisation, 
  fetchUnterorgkondwertdurchschnitte, 
  fetchKonditionen, fetchKondition, 
  fetchProdukt, 
  fetchNumerischeKonditionswerte, fetchNumerischeKonditionswert, 
  fetchInstitute,
  BASIS_URL
}