import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { Store } from "../../store/store";
import PageComponent from "./Page";
import { DataValueWettbewerber } from "../../store/wettbewerberSlice";
import { DateValue, GesamtData } from "../../objekt/types"
import { dataManager, extractLabelsFromUrl, findIds, getCurrentDateFormatted, getDateEarlierFormatted } from "../../utils/GraphenUtils";

interface GraphenSeiteProps {
    id: string[],
    titel: string
}

export default function GraphenSeite(props: GraphenSeiteProps) {

    const konditonProdukt = useSelector((state: Store) => state.konditionProdukt);
    const navigation = useSelector((state: Store) => state.navigation);
    const verbandUnterorganisationen = useSelector((state: Store) => state.verbandUnterorganisationen);

    const [data, setData] = useState<DateValue[]>([]);
    const [wettbewerberData, setWettbewerberData] = useState<DataValueWettbewerber[]>([]);
    const [konditionsData, setKonditionsData] = useState<GesamtData[]>([]);
    const [selectedRange, setSelectedRange] = useState<string>('3 Monate');

    useEffect(() => {
        const currentUrl = window.location.href;
        const labels = extractLabelsFromUrl(currentUrl);
        const idArray = findIds(labels, navigation.items);
        
        //2024-09-01T00:00:00Z
        const startDatum: string = getDateEarlierFormatted(selectedRange);
        const endDatum: string = getCurrentDateFormatted();

        dataManager(idArray, startDatum, endDatum, setData, setWettbewerberData, setKonditionsData, selectedRange, konditonProdukt, verbandUnterorganisationen);
    }, [props.id, selectedRange]);

    useEffect(() => {
        setData([]);
        setSelectedRange("3 Monate");
    }, [props.id])

    return (
        <div style={{ paddingLeft: '15%', paddingTop: '2%', paddingBottom: '5%' }}>
            <PageComponent chartData={data} wettbewerberdata={wettbewerberData} selectedRange={selectedRange} setSelectedRange={setSelectedRange} konditionsData={konditionsData} />
        </div>
    )
}


