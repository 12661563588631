import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VerbandUnterorganisationen {
    verbandsId: number,
    verbandsname: string,
    unterorganisationsId: number,
    unterorganisationsName: string
}

const initalState: VerbandUnterorganisationen[] = []

const verbandUnterorganisationenSlice = createSlice({
    name: "verbandUnterorganisationen",
    initialState: initalState,
    reducers: {
        addverbandUnterorganisationen(state: VerbandUnterorganisationen[], action: PayloadAction<VerbandUnterorganisationen>){

            state.push({verbandsId: action.payload.verbandsId, verbandsname: action.payload.verbandsname, unterorganisationsId: action.payload.unterorganisationsId, unterorganisationsName: action.payload.unterorganisationsName})
        },
    }
})

export const {
    addverbandUnterorganisationen,
} = verbandUnterorganisationenSlice.actions

export default verbandUnterorganisationenSlice.reducer