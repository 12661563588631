import React from 'react';
import { Dropdown, DropdownItem, DropdownToggle } from 's-ms-component-library';

interface ComboBoxProps {
  value: string;
  onChange: (value: string) => void;
  options: string[];
  label?: string;
}

const ComboBox: React.FC<ComboBoxProps> = ({ value, onChange, options, label }) => {
  return (
    <div style={{display: 'flex', alignContent: 'center'}}>
      <label style={{alignContent: 'center', paddingRight: '1vW'}}>{label}</label>
      <Dropdown
        id="dropdown"
        toggleElement={<DropdownToggle caret>{value}</DropdownToggle>}
      >
        {options.map((option) => (
          <DropdownItem
            key={option}
            onClick={() => onChange(option)}
            active={option === value}
          >
            {option}
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );
};

export default ComboBox;
