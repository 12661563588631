import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Store } from "../store/store";
import { NavItemTree } from "s-ms-component-library";
import { useEffect, useState } from "react";
import { SparkasseDto } from "../rest/entities/sparkassen";
import { SparkassenverbandDto } from "../rest/entities/sparkassenverbaendeNew";
import { UnterorganisationDto } from "../rest/entities/unterorganisationenNew";
import { fetchSparkassen, fetchUnterorganisationen, fetchSparkassenverbande, fetchProdukt, fetchKonditionen, fetchSparkassenverband, BASIS_URL } from "../service/rest-service";
import { setAll } from "../store/navigationSlice";
import GraphenSeite from "../ui/components/GraphenSeite";
import { addUrl, UrlState } from "../store/urlSlice";
import { KonditionDto } from "../rest/entities/konditionenNew";
import { ProduktDto } from "../rest/entities/produkteNew";
import { addKonditionProdukt, KonditionAndProdukt } from "../store/konditionProduktSlice";
import WelcomeComponent from "../ui/components/welcome/welcome";
import { addverbandUnterorganisationen as addVerbandUnterorganisationen, VerbandUnterorganisationen } from "../store/verbandUnterorganisationSlice";
import { addVerbandSparkassen, VerbandSparkasse } from "../store/verbandSparkassenSlice";

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {

    const dispatch = useDispatch();
    const ids = useSelector((state: Store) => state.ids);

    const [urls, setUrls] = useState<UrlState[]>([])

    const getKondUebersichten = (): NavItemTree[] => (
        [
            {
                id: "1",
                label: 'Gesamt',
                active: true,
            },
            {
                id: "2",
                label: 'Sparbrief',
                active: true,
            },
            {
                id: "3",
                label: 'Tagesgeld',
                active: true,
            },
            {
                id: "4",
                label: 'Dispozinsen',
                active: true,
            }
        ]
    )

    const getUnterorganisation = (): NavItemTree[] => (
        [
            {
                id: "1",
                label: "Sparbrief",
                active: true
            },
            {
                id: "2",
                label: "Tagesgeld",
                active: true
            },
            {
                id: "3",
                label: "Dispozinsen",
                active: true
            }
        ]
    )


    const getSparkassenUebersicht = (): NavItemTree[] => (
        [
            {
                id: "1",
                label: "Sparbrief",
                active: true
            },
            {
                id: "2",
                label: "Tagesgeld",
                active: true
            },
            {
                id: "3",
                label: "Dispozinsen",
                active: true
            }
        ]
    )

    const fetchDataSparkassenverbande = async (): Promise<SparkassenverbandDto[]> => {
        const url: string = `${BASIS_URL}/sparkassenverbaende`;
        return await fetchSparkassenverbande(url);
    }

    const fetchDataSparkassen = async (): Promise<SparkasseDto[]> => {
        const url: string = `${BASIS_URL}/sparkassen`;
        return await fetchSparkassen(url);
    }

    const fetchDataUnterorganisationen = async (): Promise<UnterorganisationDto[]> => {
        const url: string = `${BASIS_URL}/unterorganisationen`;
        return await fetchUnterorganisationen(url);
    }

    const fetchDataKonditionen = async (): Promise<KonditionDto[]> => {
        const url: string = `${BASIS_URL}/konditionen`;
        return await fetchKonditionen(url)
    }

    const fetchDataProduk = async (url: string): Promise<ProduktDto> => {
        return await fetchProdukt(url)
    }

    const fetchDataSparkassenVerband = async (url: string): Promise<SparkassenverbandDto> => {
        return await fetchSparkassenverband(url);
    }

    useEffect(() => {

        const buildNavigationBar = async () => {
            try {
                const [sparkassenVerbaende, unterorganisationen, sparkassen] = await Promise.all([
                    fetchDataSparkassenverbande(),
                    fetchDataUnterorganisationen(),
                    fetchDataSparkassen()
                ]);

                const verbandUnterorganisationen = await buildVerbundUnterorganisatinsArray(unterorganisationen);
                const verbandSparkassen = await buildVerbundSparkassenArray(sparkassen);

                const getUnterorganisationenForVerband = (verbaendeId: number) =>
                    unterorganisationen.filter(unterorganisation =>
                        verbandUnterorganisationen.some(vu =>
                            vu.verbandsId === verbaendeId && vu.unterorganisationsId === unterorganisation.id
                        )
                    );
                const getSparkassenForVerband = (verbaendeId: number) =>
                    sparkassen.filter(sparkasse =>
                        verbandSparkassen.some(vs =>
                            vs.verbandsId === verbaendeId && vs.sparkassenId === sparkasse.id
                        )
                    );
                

                const unterorganisationenLastId = unterorganisationen[unterorganisationen.length - 1]?.id! + 1;

                const result = sparkassenVerbaende.map((sparkassenVerband: SparkassenverbandDto) => {
                    const filteredUnterorganisationen = getUnterorganisationenForVerband(sparkassenVerband.id!);
                    const filteredSparkassen = getSparkassenForVerband(sparkassenVerband.id!);

                    return {
                        id: sparkassenVerband.id!.toString(),
                        label: sparkassenVerband.name!,
                        active: true,
                        children: [
                            {
                                label: "Konditionsübersicht",
                                id: `0`,
                                active: false,
                                children: getKondUebersichten()
                            },
                            ...filteredUnterorganisationen.map((unterorganisation: UnterorganisationDto) => ({
                                id: unterorganisation.id!.toString(),
                                label: unterorganisation.name!,
                                active: false,
                                children: getUnterorganisation()
                            })),
                            {
                                id: unterorganisationenLastId.toString(),
                                label: "Sparkassen",
                                active: false,
                                children: filteredSparkassen.map((sparkasse: SparkasseDto) => ({
                                    id: sparkasse.id!.toString(),
                                    label: sparkasse.name!,
                                    active: false,
                                    children: getSparkassenUebersicht()
                                }))
                            }
                        ]
                    };
                });

                const createUrlsAndId = (nodes: NavItemTree[], path: string = '', parentId = ''): UrlState[] => {
                    let urlIdList: UrlState[] = [];
                    for (const node of nodes) {
                        const formattedId = `${parentId}${parentId ? '.' : ''}${node.id}`;
                        const newPath = `${path}/${node.label.replace(/\s+/g, '-').toLowerCase()}`;
                        const urlIdItem: UrlState = { url: newPath, id: formattedId };
                        dispatch(addUrl(urlIdItem));
                        urlIdList.push(urlIdItem);
                        if (node.children) {
                            urlIdList = urlIdList.concat(createUrlsAndId(node.children, newPath, formattedId));
                        }
                    }
                    return urlIdList;
                };

                const convertResultToNavItemTree = (resultArray: { id: number | undefined; label: string | undefined; active: boolean; children: any[]; }[]): NavItemTree[] => {
                    return resultArray.map(item => ({
                        id: item.id?.toString() || '',
                        label: item.label || '',
                        active: item.active,
                        children: convertResultToNavItemTree(item.children)
                    }));
                };

                const newUrlIdList = createUrlsAndId(result);
                setUrls(newUrlIdList);

                dispatch(setAll(result));

            } catch (error) {
                console.error("Fehler beim Abrufen der Daten:", error);
            }
        };

        const buildKonditionAndProduktObjekt = async () => {
            try {
                const konditionen = await fetchDataKonditionen();
                const results = await Promise.all(konditionen.map(async (value) => {
                    const produkt: ProduktDto = await fetchDataProduk(value._links?.produkt.href!);
                    const konditionenAndProdukt: KonditionAndProdukt = {
                        konditionId: value.id!,
                        name: value.name!,
                        wertType: value.wertTyp!,
                        produktId: produkt.id!,
                        art: produkt.name!
                    };
                    dispatch(addKonditionProdukt(konditionenAndProdukt));
                    return konditionenAndProdukt;
                }));
                return results;
            } catch (error) {
                console.error('Fehler beim Erstellen des KonditionAndProdukt-Objekts', error);
            }
        };

        buildNavigationBar();
        buildKonditionAndProduktObjekt();
    }, [dispatch]);

    async function buildVerbundUnterorganisatinsArray(unterorganisationen: UnterorganisationDto[]): Promise<VerbandUnterorganisationen[]> {

        const verbandUnterorganisationenPromises = unterorganisationen.map(async (unterorganisation) => {
            const sparkassenVerband: SparkassenverbandDto = await fetchDataSparkassenVerband(unterorganisation._links?.sparkassenverband.href!);

            const verbandUnterorganisationen: VerbandUnterorganisationen = {
                verbandsId: sparkassenVerband.id!,
                verbandsname: sparkassenVerband.name!,
                unterorganisationsId: unterorganisation.id!,
                unterorganisationsName: unterorganisation.name!
            }

            dispatch(addVerbandUnterorganisationen(verbandUnterorganisationen))
            return verbandUnterorganisationen;

        })
        return Promise.all(verbandUnterorganisationenPromises);
    }

    async function buildVerbundSparkassenArray(spakassen: SparkasseDto[]): Promise<VerbandSparkasse[]> {

        const verbandSparkassenPromises = spakassen.map(async (spakasse) => {
            const sparkassenVerband: SparkassenverbandDto = await fetchDataSparkassenVerband(spakasse._links?.sparkassenverband.href!);

            const verbandUnterorganisationen: VerbandSparkasse = {
                verbandsId: sparkassenVerband.id!,
                verbandsName: sparkassenVerband.name!,
                sparkassenId: spakasse.id!,
                sparkassenName: spakasse.name!
            }

            dispatch(addVerbandSparkassen(verbandUnterorganisationen))
            return verbandUnterorganisationen;

        })
        return Promise.all(verbandSparkassenPromises);
    }

    return (
        <Routes>
            {urls.map(({ url, id }) => {
                return (
                    <Route
                        key={`${url}-${id}`}
                        path={url}
                        element={<GraphenSeite id={ids} titel="" />}
                    />
                );
            })}
            <Route path="/" element={<WelcomeComponent />} />
        </Routes>
    );
}

export default AppRouter;