import React, { useEffect, useState } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { processData, predefinedColors, roundToThreeDecimals  } from '../../utils/chartDataUtils';
import { DateValue, DateValueInstitut, GesamtData  } from '../../objekt/types';
import ChartTable from './ChartTable';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface LineChartProps {
  dataMap: DateValue[];
  title?: string;
  width?: string;
  height?: string;
  timeRange: string;
  checkboxState: number;
  wettbewerberData: DateValueInstitut[],
  konditionsData: GesamtData[]
}

const LineChart: React.FC<LineChartProps> = ({ dataMap, title, width, height, timeRange, checkboxState, wettbewerberData, konditionsData }) => {
  const [processDates, setProcessDates] = useState<string[]>([]);
  let groupedData
  if (dataMap.length === 0) {
    groupedData = konditionsData.reduce((acc, item) => {
      const key = `${item.nameDesProdukts}-${item.untergoranisationsName}` || 'Default';
      if (!acc[key]) acc[key] = []; 
    
      acc[key].push({
        date: item.date,
        value: item.value,
        name: item.nameDesProdukts,
        wettbewerber: item.untergoranisationsName, 
      });
    
      return acc;
    }, {} as { [key: string]: DateValue[] });
  } else {
    groupedData = dataMap.reduce((acc, item) => {
      const key = item.name || 'Default';
      if (!acc[key]) acc[key] = [];
      acc[key].push(item);
      return acc;
    }, {} as { [key: string]: DateValue[] });
  }
  

  const longestDataGroup = Object.values(groupedData).reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  }, []);

  useEffect(() => {
    const dates = processData(longestDataGroup || [], timeRange).map(item => item.date);
    setProcessDates(dates);
  }, [checkboxState, dataMap, wettbewerberData, timeRange, konditionsData]);


  // Chart datasets for `dataMap`
  const chartDatasets = Object.entries(groupedData).map(([name, dataset], index) => {
    const processedData = processData(dataset, timeRange);
    let alignedData
    if (processedData[0].wettbewerber) {
      alignedData = processedData.map(item => item.value);
    } else {
      alignedData = processDates.map(date => {
        const matchingEntry = processedData.find(item => item.date === date);
        return matchingEntry ? matchingEntry.value : null;
      });
    }

    return {
      label: name,
      data: alignedData as number[],
      fill: false,
      backgroundColor: predefinedColors[index % predefinedColors.length],
      borderColor: predefinedColors[index % predefinedColors.length],
      borderWidth: 2,
      spanGaps: true,
    };
  });

  if (checkboxState === 1) {
    // Filter out invalid entries
    const validWettbewerberData = wettbewerberData.filter(
      item => item.date && item.value !== undefined && item.name
    );

    // Group `wettbewerberData` by both `name` and `wettbewerber`
    const wettbewerberGroupedData = validWettbewerberData.reduce((acc, item) => {
      const key = `${item.wettbewerber}: ${item.name}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(item);
      return acc;
    }, {} as { [key: string]: DateValueInstitut[] });

    // Add datasets for `wettbewerberData`
    Object.entries(wettbewerberGroupedData).forEach(([key, dataset], index) => {
      const processedData = processData(dataset, timeRange);

      const alignedData = processDates.map(date => {
        const matchingEntry = processedData.find(item => item.date === date);
        return matchingEntry ? matchingEntry.value : null;
      });
  
      chartDatasets.push({
        label: key,
        data: alignedData as number[], 
        fill: false,
        backgroundColor: predefinedColors[(chartDatasets.length + index) % predefinedColors.length],
        borderColor: predefinedColors[(chartDatasets.length + index) % predefinedColors.length],
        borderWidth: 2,
        spanGaps: true,
      });
    });
  }

  // Configure chart data
  const chartConfig = {
    labels: processData(longestDataGroup || [], timeRange).map(item => item.date),
    datasets: chartDatasets,
  };

  // Chart.js options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        title: { display: true, text: 'Datum' },
        ticks: { autoSkip: false, maxRotation: 45, minRotation: 0 },
      },
      y: {
        title: { display: true, text: 'Prozentwert' },
      },
    },
  };

  // Prepare table data
  const tableData: DateValue[][] = Object.values(groupedData).map(dataset => {
    return dataset.map(item => ({
      date: item.date,
      value: roundToThreeDecimals(item.value),
      name: item.name,
      wettbewerber: item.wettbewerber
    }));
  });

  const tableDataWettbewerber: DateValueInstitut[][] = Object.values(groupedData).map(dataset => {
    return wettbewerberData.map(item => ({
      date: item.date,
      value: roundToThreeDecimals(item.value),
      name: item.name,
      wettbewerber: item.wettbewerber
    }));
  });

  return (
    <div>
      <div style={{ width, height }}>
        <Line data={chartConfig} options={options} />
      </div>
      <ChartTable
        groupedData={tableData}
        checkboxState={checkboxState}
        wettbewerberData={tableDataWettbewerber}
      />
    </div>
  );
};


export default LineChart;
