import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UrlState{
    url: string,
    id: string
}

const initalState: UrlState[] = [{url: "", id: ""}]

const urlSlice = createSlice({
    name: "url",
    initialState: initalState,
    reducers: {
        addUrl(state: UrlState[], action: PayloadAction<UrlState>){

            state.push({url: action.payload.url, id: action.payload.id})
        },
    }
})

export const {
    addUrl
} = urlSlice.actions

export default urlSlice.reducer