import React, { useEffect, useState } from 'react';
import { Flap } from 's-ms-component-library';
import ComboBox from './ComboBox';
import './ChartTable.scss';
import { DateValue, DateValueInstitut } from '../../objekt/types';
import { extractLabelsFromUrl } from '../../utils/GraphenUtils';

interface ChartTableProps {
  groupedData: DateValue[][];
  checkboxState: number;
  wettbewerberData: DateValueInstitut[][];
}

const tableStyle: React.CSSProperties = {
  borderCollapse: 'collapse',
  width: 'calc(100% / 12 - 20px)',
  minWidth: '150px',
  border: '1px solid #ddd',
};

const cellStyle: React.CSSProperties = {
  border: '1px solid #ddd',
  padding: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
};


const ChartTable: React.FC<ChartTableProps> = ({ groupedData, checkboxState, wettbewerberData }) => {
  const [selectedName, setSelectedName] = useState<string>('Select');

  const hasWettbewerber = groupedData.length > 0 && groupedData[0].length > 0 && groupedData[0][0]?.wettbewerber;
  let flattenedData;
  let filteredKonditionData: { [key: string]: DateValue[] } = {}
  const currentUrl = window.location.href;
  const labels = extractLabelsFromUrl(currentUrl);
  const isKonditionsübersicht = labels[1] === "konditionsübersicht";
  let title = ""
  if (labels[1] === "sparkassen") {
    title = `${String(labels[2])} `;
  } else {
    title = `${String(labels[1])}`;
  }
  const groupByMonth = <T extends DateValue | DateValueInstitut>(
    data: T[]
  ): { [key: string]: T[] } => {
    if (!Array.isArray(data) || data.length === 0) {
      return {};
    }
    return data.reduce((acc, item) => {
      const [day, month, year] = item.date.split('.');
      const monthYear = `${month}.${year}`;
      if (!acc[monthYear]) acc[monthYear] = [];
      acc[monthYear].push(item);
      return acc;
    }, {} as { [key: string]: T[] });
  };




  if (hasWettbewerber) {
    flattenedData = groupedData.flat();
    filteredKonditionData = groupByMonth(flattenedData.filter(
      item => item.name === selectedName
    ))
  }

  const uniqueKonditionsSparkassen = Array.from(
    new Set(flattenedData?.map(data => data.wettbewerber))
  );

  // Flatten wettbewerberData for easier processing
  const flattenedWettbewerberData = wettbewerberData.flat();


  // Extract unique names from groupedData
  const uniqueNames = Array.from(
    new Set(
      groupedData.flatMap(monthData =>
        monthData.map(item => item.name || 'Dispozins')
      )
    )
  );

  // Filter `groupedData` and `wettbewerberData` based on `selectedName`
  const filteredData = groupedData.map(monthData =>
    monthData.filter(item => item.name === selectedName)
  );
  const filteredWettbewerberData = flattenedWettbewerberData.filter(
    item => item.name === selectedName
  );

  // Group the filtered data by month
  const groupedByMonth = groupByMonth(filteredData.flat());
  const wettbewerberByMonth = groupByMonth(
    checkboxState === 1 ? filteredWettbewerberData : []
  );

  // Extract all unique wettbewerber strings
  const uniqueWettbewerber = Array.from(
    new Set(flattenedWettbewerberData.map(data => data.wettbewerber))
  );

  const groupedByMonthWettbewerber = groupByMonth(checkboxState === 1 ? filteredWettbewerberData : []);



  useEffect(() => {
    setSelectedName(uniqueNames[0]);
  }, [groupedData]);

  console.log(uniqueWettbewerber);
  

  return (
    <div>
      <br />
      <Flap expandedOnInit showBadgeOnlyWhenClosed titel="Tabelle anzeigen">
        <div>
          {uniqueNames.length > 1 && (
            <div style={{ marginTop: '10px' }}>
              <ComboBox
                value={selectedName}
                onChange={(value) => setSelectedName(value)}
                options={uniqueNames}
              />
            </div>
          )}
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              justifyContent: 'left',
              maxWidth: '100%',
            }}
          >
            {isKonditionsübersicht ? (
              // Alternative table when isKonditionsübersicht is true
              Object.entries(groupedByMonth).map(([month, monthData], index) => (
                <table key={index} style={tableStyle}>
                  <thead>
                    <tr>
                      <th style={cellStyle}>{month}</th>
                      {uniqueKonditionsSparkassen.map((wettbewerber) => (
                        <th key={wettbewerber} style={cellStyle}>
                          {wettbewerber}
                        </th>
                      ))}
                      {checkboxState === 1 &&
                        uniqueWettbewerber.map((wettbewerber) => (
                          <th key={wettbewerber + 'checkbox'} style={cellStyle}>
                            {wettbewerber}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Get unique dates for the current month */}
                    {Array.from(new Set(monthData.map((item) => item.date))).map((date, rowIndex) => (
                      <tr key={rowIndex}>
                        <td style={cellStyle}>{date}</td>
                        {uniqueKonditionsSparkassen.map((wettbewerber) => {
                          const matchedData = monthData.find(
                            (data) => data.date === date && data.wettbewerber === wettbewerber
                          );
                          return (
                            <td key={`${date}-${wettbewerber}`} style={cellStyle}>
                              {matchedData ? matchedData.value : '-'}
                            </td>
                          );
                        })}
                        {checkboxState === 1 &&
                          uniqueWettbewerber.map((wettbewerber) => {
                            const wettbewerberDataItem = (wettbewerberByMonth[month] || []).find(
                              data =>
                                data.date === date &&
                                data.wettbewerber === wettbewerber
                            )
                            return (
                              <td
                                key={`${rowIndex}-${wettbewerber}`}
                                style={cellStyle}
                              >
                                {wettbewerberDataItem ? wettbewerberDataItem.value : '-'}
                              </td>
                            );
                          })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
            ) : (
              Object.entries(groupedByMonth).map(([month, monthData], index) => (
                <table
                  id={`table-${index}`}
                  key={month}
                  style={tableStyle}
                >
                  <thead>
                    <tr>
                      <th
                        style={cellStyle}
                      >
                        {`${month}`}
                      </th>
                      <th
                        style={cellStyle}
                      >
                        {title}
                      </th>
                      {checkboxState === 1 &&
                        uniqueWettbewerber.map(wettbewerber => (
                          <th
                            key={wettbewerber}
                            style={cellStyle}
                          >
                            {wettbewerber}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {monthData.map((item, idx) => (
                      <tr key={idx}>
                        <td
                          style={cellStyle}
                        >
                          {item.date}
                        </td>
                        <td
                          style={cellStyle}
                        >
                          {item.value}
                        </td>
                        {checkboxState === 1 &&
                          uniqueWettbewerber.map(wettbewerber => {
                            // Format the current month
                            const wettbewerberDataItem = (wettbewerberByMonth[month] || []).find(
                              data =>
                                data.date === item.date &&
                                data.wettbewerber === wettbewerber
                            )
                            return (
                              <td
                                key={`${idx}-${wettbewerber}`}
                                style={cellStyle}
                              >
                                {wettbewerberDataItem ? wettbewerberDataItem.value : '-'}
                              </td>
                            );
                          })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))
            )}
          </div>
        </div>
      </Flap>
    </div>
  );
};

export default ChartTable;
